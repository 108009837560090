// src/contexts/ConstantsContext.js
import React, { useContext } from "react";

const ConstantsContext = React.createContext();

export function useConstants() {
  return useContext(ConstantsContext);
}

export function ConstantsProvider({ links, Logo, children }) {
  const value = {
    links: links,
    Logo: Logo,
  };
  return (
    <ConstantsContext.Provider value={value}>
      {children}
    </ConstantsContext.Provider>
  );
}
