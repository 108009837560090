// src/components/ui/Textarea.js
import React from "react";

export function Textarea({ className = "", maxLength, ...props }) {
  return (
    <div className="relative">
      <textarea
        className={`w-full border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500 ${className}`}
        maxLength={maxLength}
        {...props}
      />
    </div>
  );
}
