import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "../ui/Button";

export default function AddItemButton({ onClick }) {
  return (
    <Button
      className="fixed rounded-full w-16 h-16 shadow-lg bg-indigo-600 hover:bg-indigo-700 text-white flex items-center justify-center"
      style={{ 
        bottom: 'calc(4rem + 100px)',  
        right: '40px'  
      }}
      onClick={onClick}
      aria-label="Add Item"
    >
      <PlusCircleIcon className="h-8 w-8" />
    </Button>
  );
}