// src/contexts/FirebaseContext.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import React, { useContext, useEffect, useState } from "react";

const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function FirebaseProvider({ config, children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [isInitialized, setIsInitilized] = useState(false);
  const [app, setApp] = useState(null);
  const [auth, setAuth] = useState(null);
  const [firestore, setFirestore] = useState(null);
  const [storage, setStorage] = useState(null);
  const [functions, setFunctions] = useState(null);

  useEffect(() => {
    try {
      const app = initializeApp(config);
      setApp(app);
      setAuth(getAuth(app));
      setFirestore(getFirestore(app));
      setStorage(getStorage(app));
      setFunctions(getFunctions(app));
      setIsInitilized(true);
    } catch (e) {
      console.error("Error initializing Firebase:", e);
      setLoading(false); //won't be able to load otherwise
    }
  }, [config]);

  useEffect(() => {
    if (isInitialized && auth) {
      const removeListener = auth.onAuthStateChanged(async (user) => {
        setLoading(true); // Ensure loading is set while handling async operations
        setCurrentUser(user);

        if (user) {
          try {
            // Check subscription status
            const customerRef = doc(
              collection(firestore, "customers"),
              user.uid
            );
            const customerDoc = await getDoc(customerRef);

            if (customerDoc.exists()) {
              const customerData = customerDoc.data();
              setIsSubscribed(customerData?.activeSubscription || false);
            } else {
              console.warn("No customer document found for this user.");
              setIsSubscribed(false);
            }
          } catch (error) {
            console.error("Error fetching subscription status:", error);
            setIsSubscribed(false);
          } finally {
            setLoading(false); // End loading regardless of success or failure
          }
        } else {
          setIsSubscribed(false);
          setLoading(false);
        }
      });

      return removeListener;
    } else {
      console.log(
        "Firebase is not initialized, cannot perform authentication yet."
      );
    }
  }, [isInitialized, auth, firestore]);

  return (
    <FirebaseContext.Provider
      value={{
        app,
        auth,
        firestore,
        functions,
        isInitialized,
        storage,
        currentUser,
        isSubscribed,
        loading,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}
