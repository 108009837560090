import React from "react";
import { Link } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseContext";

export default function Ad() {
  const { isSubscribed } = useFirebase();

  return (
    !isSubscribed && (
      <div className="flex flex-col items-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.joinsecret.com/?via=benjamin"
          className="mb-4"
        >
          <img
            src="https://res.cloudinary.com/duokwfdna/image/upload/v1731253130/jebwz57e694rqi4nnjbp.png"
            alt="Join Secret Ad"
            className="max-w-[250px] max-h-[250px] w-auto h-auto rounded shadow-md"
          />
        </a>
        <Link className="link" to="/billing">
          Don't like ads? Subscribe to remove
        </Link>
      </div>
    )
  );
}
