import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

export function userCollectionQuery(firestore, userId) {
  const collectionsRef = collection(firestore, "collections");
  return query(collectionsRef, where("userId", "==", userId));
}
export function collectionItemsRef(firestore, collectionId) {
  return collection(firestore, `collections/${collectionId}/items`);
}
export async function collectionItemsSnapshot(firestore, collectionId) {
  const itemsRef = collectionItemsRef(firestore, collectionId);
  const itemsQuery = query(itemsRef, orderBy("order", "desc"));
  return await getDocs(itemsQuery);
}

export function collectionSpecificItemRef(firestore, collectionId, itemId) {
  return doc(firestore, `collections/${collectionId}/items/${itemId}`);
}
