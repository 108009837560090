import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { MAX_COLLECTIONS, MAX_ITEMS } from "../../constants";

const PRICING_PLANS = [
  {
    name: 'Free',
    price: '$0',
    description: "Get started with basic features to organize your collections.",
    features: [
      `Limit of ${MAX_ITEMS.unsubscribed} items per collection`,
      `Up to ${MAX_COLLECTIONS.unsubscribed} collections`,
      'Ad supported',
    ],
    cta: 'Get Started',
    href: '/signup?plan=free',
    featured: false,
  },
  {
    name: 'Premium',
    price: '$2.99',
    description: "An ad-free plan for enhanced collection organization and sharing.",
    features: [
      `Limit of ${MAX_ITEMS.subscribed} items per collection`,
      `Up to ${MAX_COLLECTIONS.subscribed} collections`,
      'No ads',
    ],
    cta: 'Get Started',
    href: '/signup?plan=premium',
    featured: true,
  },
];

const TwoCardPricing = () => {
  return (
    <section id="pricing" className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
        <div className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }}
        />
      </div>
      <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-semibold leading-7 text-indigo-600">Upgrade Your Collection Game</h2>
          <p className="mt-2 text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
            Get More from Collectify with Premium Tools
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-lg font-medium text-gray-600 sm:text-xl/8 text-center">
          Find the plan that elevates your collection experience—organize, share, and grow your collections like never before.
        </p>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
        {PRICING_PLANS.map((plan) => (
          <div
            key={plan.name}
            className={`rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 ${
              plan.featured ? 'bg-white shadow-2xl scale-105 sm:scale-110' : 'bg-gray-50'
            }`}
          >
            <h3 className="text-base font-semibold leading-7 text-indigo-600">{plan.name}</h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span className="text-5xl font-semibold tracking-tight text-gray-900">{plan.price}</span>
              <span className="text-base text-gray-500">/month</span>
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">{plan.description}</p>
            <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10">
              {plan.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-600" />
                  {feature}
                </li>
              ))}
            </ul>
            <a href={plan.href} className={`mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10 ${plan.featured ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300'}`}>
              {plan.cta}
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TwoCardPricing;