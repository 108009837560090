// src/pages/LoginPage.js
import { signOut } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseContext";
import AppPageLayout from "../layouts/AppPageLayout";

export default function LogoutPage() {
  const navigate = useNavigate();
  const { auth } = useFirebase();
  useEffect(() => {
    async function logout() {
      try {
        await signOut(auth);
      } catch (e) {
        console.warn(e);
      }

      navigate("/", { replace: true });
    }
    logout();
  }, [navigate, auth]);

  return <AppPageLayout>Logging you out. Just one second</AppPageLayout>;
}
