// src/pages/PublicCollection.js
import CircularProgress from "@mui/material/CircularProgress"; // Example using Material-UI

import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Ad from "../common/containers/Ad";
import { useFirebase } from "../common/contexts/FirebaseContext";
import ItemCard from "../components/ItemCard";

export default function PublicCollection() {
  const { firestore } = useFirebase();
  const { collectionId } = useParams();
  const [collectionData, setCollectionData] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // Fetch the public collection data
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const collectionDocRef = doc(firestore, "collections", collectionId);
        const collectionDoc = await getDoc(collectionDocRef);

        if (collectionDoc.exists()) {
          const data = collectionDoc.data();
          setCollectionData(data);
        } else {
          console.error("Collection does not exist.");
        }
      } catch (error) {
        console.error("Error fetching collection:", error);
        setLoading(false);
      }
    };
    setLoading(true);
    fetchCollection();
  }, [collectionId, firestore]);

  // Fetch items in the public collection
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const collectionDocRef = doc(firestore, "collections", collectionId);
        const itemsRef = collection(collectionDocRef, "items");
        const q = query(itemsRef, orderBy("order"));
        const querySnapshot = await getDocs(q);
        const fetchedItems = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setItems(fetchedItems);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (collectionData) {
      fetchItems();
    }
  }, [collectionData, collectionId, firestore]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  } else if (collectionData === null) {
    return <div>Couldn't find what you're asking for</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {collectionData.name}
          </h1>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto p-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          {items.length === 0 && <div>Sorry no items in this collection</div>}
          {items.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              isPublicView={true} // Pass a prop to hide edit/delete buttons
            />
          ))}
          <Ad />
        </div>
      </main>
    </div>
  );
}
