import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useFirebase } from "../common/contexts/FirebaseContext";
import { MAX_COLLECTIONS } from "../constants";
import { Button } from "../ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";
import { Input } from "../ui/Input";

export default function AddCollectionModal({ isOpen, collections, onFinish }) {
  const { isSubscribed, auth, firestore } = useFirebase();
  let max = MAX_COLLECTIONS.unsubscribed;
  if (isSubscribed) {
    max = MAX_COLLECTIONS.subscribed;
  }
  const outOfCollections = collections.length >= max;

  const handleAddCollection = async (newCollection) => {
    const user = auth.currentUser;
    if (user) {
      const maxOrder =
        collections.length > 0
          ? Math.max(...collections.map((c) => c.order ?? 0))
          : 0;

      await addDoc(collection(firestore, "collections"), {
        ...newCollection,
        userId: user.uid,
        order: maxOrder + 1, // Assign the next order value
      });
    }
  };

  const [name, setName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    const user = auth.currentUser;
    if (!user) {
      alert("You must be logged in to create a collection.");
      return;
    }

    const newCollection = {
      name,
      createdAt: new Date(),
      userId: user.uid,
    };

    await handleAddCollection(newCollection);
    onFinish();
    setName("");
    setIsSaving(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {outOfCollections ? "Out of Collections" : "Create New Collection"}
          </DialogTitle>
        </DialogHeader>
        {!outOfCollections && (
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 py-4">
              {/* Collection Name Field with Character Countdown */}
              <div className="relative">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                >
                  Collection Name <span className="text-red-500">*</span>
                </label>
                <Input
                  id="name"
                  name="name"
                  required
                  aria-required="true"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={21}
                  className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
                />
                <p className="text-xs text-gray-500 mt-1 text-right">
                  {21 - name.length} characters remaining
                </p>
              </div>
            </div>

            {/* Close and Save Buttons */}
            <div className="flex justify-end space-x-4">
              <Button variant="ghost" onClick={onFinish}>
                Close
              </Button>
              <Button type="submit">{isSaving ? "Saving.." : "Save"}</Button>
            </div>
          </form>
        )}
        {outOfCollections && !isSubscribed && (
          <div>
            <div>
              Please purchase a subscription before making new collections
            </div>
            <Button variant="ghost" onClick={onFinish}>
              Close
            </Button>
            <Link to="/billing">
              <Button>Upgrade now</Button>
            </Link>
          </div>
        )}
        {outOfCollections && isSubscribed && (
          <div>
            <div>You are out of collections.</div>
            <Button variant="ghost" onClick={onFinish}>
              Close
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
