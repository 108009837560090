// src/components/CollectionSelector.js
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { viewMyCollection } from "../utils/Links";
import AddCollectionModal from "./AddCollectionModal";
export default function CollectionSelector({
  className,
  collections,
  selectedCollectionId,
}) {
  const navigate = useNavigate();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className={`z-30 relative w-64 bg-white shadow-md p-4 ${className}`}>
      <div
        className="w-full flex"
        onClick={() => setDropdownOpen(!isDropdownOpen)}
      >
        <span className="flex-1">
          {selectedCollectionId
            ? collections.find(
                (collection) => collection.id === selectedCollectionId
              )?.name
            : "Pick a collection"}
        </span>
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
      {isDropdownOpen && (
        <div className="dropdown-open">
          <ul className="space-y-2">
            {collections.map((collection) => (
              <li
                key={collection.id}
                className="flex flex-wrap items-start justify-between p-2 bg-gray-100 rounded-md shadow-sm relative"
              >
                <Button
                  variant="ghost"
                  className="flex-grow justify-start text-left break-words"
                  onClick={() => {
                    navigate(viewMyCollection(collection.id));
                    setDropdownOpen(false);
                  }}
                >
                  <span className="break-words">{collection.name}</span>
                  {collection.isPublic && (
                    <GlobeAltIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                  )}
                  {collection.id === selectedCollectionId && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </Button>
              </li>
            ))}
          </ul>
          <Button className="w-full mt-4" onClick={() => setAddModalOpen(true)}>
            New Collection
          </Button>
        </div>
      )}

      {isAddModalOpen && (
        <AddCollectionModal
          isOpen={isAddModalOpen}
          collections={collections}
          onFinish={() => setAddModalOpen(false)}
        />
      )}
    </div>
  );
}
