export function viewMyCollection(collectionid) {
  return `/my/collections/${collectionid}`;
}

export function viewAllOfMyCollections() {
  return `/my/collections`;
}

export function viewPublicCollection(collectionid) {
  return `/p/collections/${collectionid}`;
}
