import React from "react";
import { RouterProvider } from "react-router-dom";
import Logo from "./common/components/Logo";
import {
  VISIBLE_LOGGED_IN_ONLY,
  VISIBLE_LOGGED_OUT_ONLY,
} from "./common/components/NavBar";
import { ConstantsProvider } from "./common/contexts/ConstantsContext";
import "./index.css";
import router from "./Router";

const links = [
  {
    caption: "Collections",
    link: "/my/collections",
    target: "",
    visible: VISIBLE_LOGGED_IN_ONLY,
  },
  {
    caption: "Billing",
    link: "/billing",
    target: "",
    visible: VISIBLE_LOGGED_IN_ONLY,
  },
  {
    caption: "Logout",
    link: "/logout",
    target: "",
    visible: VISIBLE_LOGGED_IN_ONLY,
  },
  {
    caption: "Login",
    link: "/login",
    target: "",
    visible: VISIBLE_LOGGED_OUT_ONLY,
  },
];

function App() {
  return (
    <ConstantsProvider links={links} Logo={Logo}>
      <div className="center-content">
        <RouterProvider router={router} />
      </div>
    </ConstantsProvider>
  );
}

export default App;
