// src/components/ui/Input.js
import React from 'react';

export function Input({ className = '', maxLength, ...props }) {
  return (
    <input
      className={`w-full border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500 ${className}`}
      maxLength={maxLength}
      {...props}
    />
  );
}