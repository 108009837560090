// src/utils/PrivateRoute.js
import CircularProgress from "@mui/material/CircularProgress"; // Example using Material-UI
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseContext";

function ProtectedRoute() {
  const { currentUser, loading } = useFirebase();
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
