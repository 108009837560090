// src/common/layouts/AuthPageLayout.js

import AppPageLayout from "./AppPageLayout";
import Panel from "./Panel";

export default function AuthPageLayout({ children, header }) {
  return (
    <AppPageLayout>
      <div className="w-full flex flex-col items-center justify-center">
        <Panel header={header} className="max-w-md">
          {children}
        </Panel>
      </div>
    </AppPageLayout>
  );
}
