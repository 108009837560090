// src/components/Auth/Login.js
import { AuthError } from "@tmavs/reactthings";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SUCCESS_REDIRECT_URL } from "../../../constants";
import { useFirebase } from "../../contexts/FirebaseContext";
import AuthPageLayout from "../../layouts/AuthPageLayout";
export default function Login() {
  const location = useLocation();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { auth } = useFirebase();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      await signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
      const from = location.state?.from?.pathname || SUCCESS_REDIRECT_URL;
      navigate(from);
    } catch (error) {
      setError("Failed to log in: " + error.message);
      console.error("Error logging in:", error);
    }
  }

  return (
    <AuthPageLayout header="Log In">
      <AuthError error={error} />

      <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
        {/* Email Input */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            ref={emailRef}
            required
            className="w-full p-3 border border-gray-300 rounded"
          />
        </div>

        {/* Password Input */}
        <div className="mb-6">
          <label htmlFor="password" className="block text-gray-700 mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            ref={passwordRef}
            required
            className="w-full p-3 border border-gray-300 rounded"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition-colors"
        >
          Log In
        </button>
      </form>

      {/* Links */}
      <div className="mt-6 text-center">
        <p className="mb-2">
          Need an account?{" "}
          <Link to="/signup" className="text-blue-500 hover:underline">
            Sign Up
          </Link>
        </p>
        <p>
          <Link to="/password-reset" className="text-blue-500 hover:underline">
            Forgot Password?
          </Link>
        </p>
      </div>
    </AuthPageLayout>
  );
}
