// src/pages/Collections.js

import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { FolderOpenIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Ad from "../common/containers/Ad";
import { useFirebase } from "../common/contexts/FirebaseContext";
import AppPageLayout from "../common/layouts/AppPageLayout";
import AddCollectionModal from "../components/AddCollectionModal";
import AddItemModal from "../components/AddItemModal";
import CollectionSelector from "../components/CollectionSelector";
import EditCollectionModal from "../components/EditCollectionModal";
import EditItemModal from "../components/EditItemModal";
import ItemCard from "../components/ItemCard";
import Sidebar from "../components/Sidebar";
import AddItemButton from "../ui/AddItemButton";
import { Button } from "../ui/Button";
import {
  collectionSpecificItemRef,
  userCollectionQuery,
} from "../utils/CollectionFirebase";
import { viewAllOfMyCollections, viewMyCollection } from "../utils/Links";

export default function Collections() {
  const { auth, firestore } = useFirebase();
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [collections, setCollections] = useState([]);
  const [items, setItems] = useState([]);
  const [areItemsLoaded, setAreItemsLoaded] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isEditingItem, setIsEditingItem] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isAddingCollection, setIsAddingCollection] = useState(false);
  const [isEditingCollection, setIsEditingCollection] = useState(false);
  const [editingCollection, setEditingCollection] = useState(null);

  // Fetch collections from Firestore
  useEffect(() => {
    const query = userCollectionQuery(firestore, user.uid);
    const unsubscribe = onSnapshot(query, (snapshot) => {
      const loadedCollections = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCollections(loadedCollections);

      if (!collectionId && loadedCollections.length > 0) {
        navigate(viewMyCollection(loadedCollections[0].id), { replace: true });
      }
    });

    return () => unsubscribe();
  }, [user.uid, firestore, collectionId]);

  // Fetch items in selected collection's "items" subcollection, ordered by "order"
  useEffect(() => {
    if (!collectionId) return;
    setItems([]);
    setAreItemsLoaded(false);

    const collectionDocRef = doc(firestore, "collections", collectionId);
    const itemsRef = collection(collectionDocRef, "items");
    const q = query(itemsRef, orderBy("order"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setItems(fetchedItems);
      setAreItemsLoaded(true);
    });

    return () => unsubscribe();
  }, [collectionId, firestore]);

  // Edit existing collection
  const handleUpdateCollection = async (updatedCollection) => {
    try {
      const collectionRef = doc(firestore, "collections", updatedCollection.id);
      await updateDoc(collectionRef, updatedCollection);
      setIsEditingCollection(false);
    } catch (error) {
      console.error("Error updating collection:", error);
    }
  };

  // Delete collection
  const handleDeleteCollection = async (deleteCollectionId) => {
    try {
      // Delete all items in the collection first
      const collectionDocRef = doc(
        firestore,
        "collections",
        deleteCollectionId
      );
      const itemsRef = collection(collectionDocRef, "items");
      const q = query(itemsRef);
      const itemsSnapshot = await getDocs(q);
      const batch = writeBatch(firestore);

      itemsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      // Delete the collection
      await deleteDoc(collectionDocRef);

      // Update the local state
      setCollections((prevCollections) =>
        prevCollections.filter((c) => c.id !== deleteCollectionId)
      );

      // If the deleted collection was the selected one, reset selection
      if (collectionId === deleteCollectionId) {
        navigate(viewAllOfMyCollections(), { replace: true });
      }

      setIsEditingCollection(false);
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  // Add item with an "order" field
  const addItem = async (item) => {
    const collectionDocRef = doc(firestore, "collections", collectionId);
    const itemsRef = collection(collectionDocRef, "items");

    const maxOrder =
      items.length > 0 ? Math.max(...items.map((item) => item.order)) : 0;

    try {
      await addDoc(itemsRef, { ...item, order: maxOrder + 1 });
      setIsAddingItem(false);
    } catch (error) {
      console.error("Error adding item: ", error);
    }
  };

  const handleEditItem = async (updatedItem) => {
    try {
      const itemRef = doc(
        firestore,
        "collections",
        collectionId,
        "items",
        updatedItem.id
      );
      await updateDoc(itemRef, updatedItem);

      // Update the local state for immediate UI feedback
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        )
      );

      setIsEditingItem(false); // Close the modal
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  // Delete item
  const deleteItem = async (itemId) => {
    try {
      const itemDocRef = collectionSpecificItemRef(
        firestore,
        collectionId,
        itemId
      );
      await deleteDoc(itemDocRef);
      setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  // Handle drag end to reorder items and update Firestore
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(sourceIndex, 1);
    reorderedItems.splice(destinationIndex, 0, movedItem);

    // Update local state
    setItems(reorderedItems);

    // Update Firestore with new order
    const batch = writeBatch(firestore);
    reorderedItems.forEach((item, index) => {
      const itemRef = collectionSpecificItemRef(
        firestore,
        collectionId,
        item.id
      );
      batch.update(itemRef, { order: index });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error("Error updating item order:", error);
    }
  };

  return (
    <AppPageLayout>
      {/* Desktop Sidebar */}
      <div className="hidden md:block">
        <Sidebar collections={collections} />
      </div>

      {/* Main Content Area */}
      <div className="flex-1 p-4 md:p-8 overflow-auto">
        {collectionId ? (
          <>
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-800 hidden md:block">
                {collections.find((c) => c.id === collectionId)?.name ||
                  "Collection"}
              </h1>
              <CollectionSelector
                className={"block md:hidden"}
                collections={collections}
                selectedCollectionId={collectionId}
              />
              <Button
                variant="ghost"
                onClick={() => {
                  const collection = collections.find(
                    (c) => c.id === collectionId
                  );
                  if (collection) {
                    setEditingCollection(collection);
                    setIsEditingCollection(true);
                  }
                }}
              >
                Edit Collection
              </Button>
            </div>

            {/* Drag and Drop Context */}
            {!areItemsLoaded && <CircularProgress />}
            {areItemsLoaded && items.length === 0 && (
              <p>No items yet. Please add one</p>
            )}

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="items" direction="horizontal" type="GRID">
                {(provided) => (
                  <div
                    className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`transition-transform ${
                              snapshot.isDragging
                                ? "z-10 transform scale-105 shadow-xl"
                                : ""
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ItemCard
                              item={item}
                              onEdit={(item) => {
                                setEditingItem(item);
                                setIsEditingItem(true);
                              }}
                              onDelete={deleteItem}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          // Default view when no collection is selected
          <div className="flex flex-col items-center justify-center h-full">
            {collections.length > 0 && (
              <CollectionSelector
                className={"block md:hidden"}
                collections={collections}
                selectedCollectionId={collectionId}
              />
            )}
            <FolderOpenIcon className="h-24 w-24 text-gray-400 mb-4" />
            <h2 className="text-2xl font-bold text-gray-700 mb-2">
              No Collection Selected
            </h2>
            <p className="text-gray-500 text-center mb-4">
              Create a new collection or select an existing one to start adding
              items.
            </p>
            <Button onClick={() => setIsAddingCollection(true)}>
              Create Your First Collection
            </Button>
          </div>
        )}

        {/* Ad Section */}
        <div className="mt-9">
          <Ad />
        </div>
      </div>

      {/* Add Item Button (Floating on Mobile) */}
      {collections.length > 0 && collectionId && (
        <div className="fixed bottom-4 right-4 md:relative md:bottom-0 md:right-0">
          <AddItemButton
            onClick={() => {
              setEditingItem(null); // Clear any existing item data
              setIsAddingItem(true); // Open modal for new item
            }}
          />
        </div>
      )}

      {/* Modals */}

      <AddItemModal
        isOpen={isAddingItem}
        onClose={() => setIsAddingItem(false)}
        onAddItem={addItem}
        totalItems={items.length}
        selectedCollection={collectionId}
      />

      <EditItemModal
        isOpen={isEditingItem}
        onClose={() => setIsEditingItem(false)}
        item={editingItem}
        onEditItem={handleEditItem}
      />
      <AddCollectionModal
        isOpen={isAddingCollection}
        onFinish={() => setIsAddingCollection(false)}
        collections={collections}
      />
      <EditCollectionModal
        isOpen={isEditingCollection}
        onClose={() => setIsEditingCollection(false)}
        collection={editingCollection}
        onSaveCollection={handleUpdateCollection}
        onDeleteCollection={handleDeleteCollection}
      />
    </AppPageLayout>
  );
}
