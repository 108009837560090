// src/common/layouts/AuthPageLayout.js

export default function Panel({ className, children, header }) {
  return (
    <div className={`w-full bg-white p-8 rounded-lg shadow-lg ${className}`}>
      {header && (
        <h2 className="text-2xl font-semibold mb-6 text-center">{header}</h2>
      )}
      {children}
    </div>
  );
}
