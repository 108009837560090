import { createBrowserRouter, Navigate } from "react-router-dom";
import Collections from "../src/pages/Collections";
import ProtectedRoute from "./common/components/ProtectedRoute";
import BillingPage from "./common/pages/BillingPage";
import LoginPage from "./common/pages/LoginPage";
import LogoutPage from "./common/pages/LogoutPage";
import PasswordResetPage from "./common/pages/PasswordResetPage";
import SignupPage from "./common/pages/SignupPage";
import Home from "./pages/LandingPage";
import PublicCollection from "./pages/PublicCollection"; // Import PublicCollection

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/password-reset",
    element: <PasswordResetPage />,
  },
  {
    path: "/p/collections/:collectionId",
    element: <PublicCollection />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/my",
        element: <Navigate to="/my/collections" replace />,
      },
      {
        path: "/my/collections",
        element: <Collections />,
      },
      {
        path: "/my/collections/:collectionId",
        element: <Collections />,
      },
      {
        path: "/billing",
        element: <BillingPage />,
      },
    ],
  },
  {
    path: "*",
    element: <p>404 Error - Nothing here...</p>,
  },
]);

export default router;
