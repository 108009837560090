// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { FirebaseProvider } from "./common/contexts/FirebaseContext";
import { FIREBASE_CONFIG } from "./constants";
import "./index.css"; // Ensure Tailwind CSS is imported

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FirebaseProvider config={FIREBASE_CONFIG}>
    <App />
  </FirebaseProvider>
);
