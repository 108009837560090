import { useEffect, useState } from "react";
import CloudinaryUpload from "../services/Cloudinary"; // Import Cloudinary component
import { Button } from "../ui/Button";
import currencies from "../ui/Currencies"; // Import currencies array
import CurrencyList from "../ui/CurrencyList"; // Import CurrencyList component
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import { Switch } from "../ui/Switch";
import { Textarea } from "../ui/Textarea";

export default function EditItemModal({ isOpen, onClose, item, onEditItem }) {
  const [currency, setCurrency] = useState(currencies[0]);
  const [forSale, setForSale] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (item) {
      setCurrency(
        currencies.find((c) => c.id === item.currency) || currencies[0]
      );
      setForSale(item.forSale || false);
      setTitle(item.title || "");
      setImageUrl(item.image || "");
      setDescription(item.description || "");
      setPrice(item.price || "");
    }
  }, [item]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onEditItem({
      id: item.id,
      title,
      description,
      image: imageUrl,
      forSale,
      price: price ? parseFloat(price) : null,
      currency: currency?.id,
    });
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[425px] max-w-[90%] mx-auto p-4 sm:p-6">
        <DialogHeader>
          <DialogTitle>Edit Item</DialogTitle>
        </DialogHeader>
        <form
          onSubmit={handleSubmit}
          className="space-y-6 overflow-y-auto max-h-[80vh] p-4"
        >
          {/* Title Field with Character Countdown */}
          <div className="relative">
            <label
              htmlFor="title"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Title <span className="text-red-500">*</span>
            </label>
            <Input
              id="title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              maxLength={26}
              className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
            />
            <p className="text-xs text-gray-500 mt-1 text-right">
              {26 - title.length} characters remaining
            </p>
          </div>

          {/* Description Field */}
          <div className="mb-4">
            <Label
              htmlFor="description"
              className="block text-sm font-medium text-gray-900 mb-1"
            >
              Description
            </Label>
            <Textarea
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              maxLength={70}
              rows={4}
              className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
            />
            <p className="text-xs text-gray-500 mt-1 text-right">
              {70 - description.length} characters remaining
            </p>
          </div>

          {/* Cloudinary Image Upload */}
          <div>
            <Label
              htmlFor="image"
              className="block text-sm font-medium text-gray-900 mb-1"
            >
              Image
            </Label>
            {/* Use setImageUrl prop for consistency */}
            <CloudinaryUpload setImageUrl={setImageUrl} />
            {imageUrl && (
              <div className="mt-4">
                <img
                  src={imageUrl}
                  alt="Item"
                  className="w-full h-48 object-contain rounded-lg shadow-sm"
                />
              </div>
            )}
          </div>

            {/* For Sale, Price, and Currency Selector */}
                <div className="space-y-4 mt-4">
                  {/* For Sale Toggle (Stacked on mobile) */}
                  <div className="flex items-center space-x-2">
                    <Label htmlFor="forSale">For Sale</Label>
                    <Switch
                      id="forSale"
                      name="forSale"
                      checked={forSale}
                      onChange={setForSale}
                    />
                  </div>

                  {/* Price and Currency in a Row */}
                  <div className="flex space-x-4">
                    {/* Price Input */}
                    <div className="relative flex-1">
                      <label
                        htmlFor="price"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                      >
                        Price
                      </label>
                      <Input
                        id="price"
                        name="price"
                        type="number"
                        step="0.01"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="0.00"
                        className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
                      />
                    </div>

                    {/* Currency Selector */}
                    <div className="relative flex-1">
                      <CurrencyList currency={currency} setCurrency={setCurrency} />
                    </div>
                  </div>
                </div>

          <div className="flex justify-end space-x-4 mt-6">
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
            <Button type="submit">Update Item</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}