import {
  ArrowsPointingOutIcon,
  Bars3Icon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { Button } from "../ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";

export default function ItemCard({ item, onEdit, onDelete, isPublicView }) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const confirmDelete = () => setIsDeleteModalOpen(true);
  const cancelDelete = () => setIsDeleteModalOpen(false);

  const handleDelete = () => {
    onDelete(item.id);
    setIsDeleteModalOpen(false);
  };

  const formattedPrice = item.price
    ? new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat(item.price))
    : null;

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden min-h-[100%] ">
      {!isPublicView && (
        <div className="p-2 bg-gray-100 flex justify-between items-center">
          <div className="mr-2 cursor-pointer">
            <Bars3Icon className="h-4 w-4 text-gray-500" />
          </div>
          <div className="flex space-x-2">
            <Button variant="ghost" size="sm" onClick={() => onEdit(item)}>
              <PencilIcon className="h-4 w-4" />
            </Button>
            <Button variant="ghost" size="sm" onClick={confirmDelete}>
              <TrashIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      )}

      <div className="relative group">
        <img
          src={item.image}
          alt={item.title}
          className="w-full p-4 mt-4 object-contain rounded-xl transform transition-transform duration-300 hover:scale-105 max-h-[300px]"
        />

        {/* View Full-Size Icon */}
        <div
          onClick={() => setIsImageModalOpen(true)}
          className="absolute bottom-2 right-2 bg-white bg-opacity-50 p-1 rounded-full cursor-pointer shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        >
          <ArrowsPointingOutIcon className="h-5 w-5 text-gray-600" />
        </div>
      </div>

      <div className="p-4">
        <h3 className="font-bold text-lg mb-2">{item.title}</h3>
        <p className="text-gray-600 text-sm mb-2">{item.description}</p>

        {formattedPrice && (
          <div className="flex justify-between items-center mt-2">
            <span className="text-green-500 font-bold">
              {formattedPrice} {item.currency}
            </span>
            {item.forSale && (
              <span className="text-white bg-red-500 px-2 py-0.5 rounded-md text-xs font-semibold transition-transform duration-300 transform">
                For Sale
              </span>
            )}
          </div>
        )}
      </div>

      {/* Full-Size Image Modal */}
      {isImageModalOpen && (
        <Dialog
          open={isImageModalOpen}
          onOpenChange={() => setIsImageModalOpen(false)}
        >
          <DialogContent className="sm:max-w-full">
            <DialogHeader>
              <DialogTitle>{item.title}</DialogTitle>
            </DialogHeader>
            <div className="p-4 flex justify-center">
              <img
                src={item.image}
                alt={item.title}
                className="rounded-lg max-w-full max-h-screen object-contain"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <Button
                variant="ghost"
                onClick={() => setIsImageModalOpen(false)}
              >
                Close
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/* Delete Confirmation Modal */}
      {!isPublicView && isDeleteModalOpen && (
        <Dialog open={isDeleteModalOpen} onOpenChange={cancelDelete}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Confirm Delete</DialogTitle>
            </DialogHeader>
            <div className="p-4">
              <p>Are you sure you want to delete this item?</p>
            </div>
            <div className="flex justify-end space-x-4">
              <Button variant="ghost" onClick={cancelDelete}>
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDelete}>
                Delete
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
