import React from "react";

const SubscriptionCard = ({
  title,
  price,
  onSubscribe,
  buttonText,
  subscriptionId,
}) => {
  if (subscriptionId === "") return null;

  return (
    // Fixed return statement
    <div className="border p-6 rounded-lg shadow-sm">
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-sm text-gray-600 mb-4 text-center">
        Subscriptions unlock immediately, allowing access to all features.
      </p>
      {/* Price */}
      <div className="flex items-baseline justify-center text-gray-900 mb-4">
        <span className="text-3xl font-semibold">$</span>
        <span className="text-5xl font-extrabold tracking-tight">{price}</span>
        <span className="text-xl font-normal text-gray-500 ml-1">
          {title.includes("Monthly") ? "/month" : "/year"}
        </span>
      </div>
      {/* Subscribe Button */}
      <button
        className="bg-green-500 text-white text-sm px-5 py-2.5 rounded-lg w-full"
        onClick={() => onSubscribe(subscriptionId)}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default SubscriptionCard;
