import React, { useState } from "react";

const CloudinaryUpload = ({ setImageUrl }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");

  const handleUpload = () => {
    setLoading(true);

    if (!window.cloudinary) {
      setLoading(false);
      setError("Cloudinary widget is not available");
      return;
    }

    window.cloudinary.openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: "collectify",
        sources: ["local", "camera", "dropbox", "google_drive"], // Limit sources to local files or camera capture
        multiple: false,
        folder: "Collectify",
        resourceType: "image",
        maxImageFileSize: 3000000, // 3MB limit for faster uploads
        clientAllowedFormats: ["jpg", "png"], // Restrict to common image formats
      },
      (error, result) => {
        setLoading(false);
        if (error) {
          setError("Upload failed. Please try again.");
          console.error("Cloudinary Error:", error);
        } else if (result.event === "success") {
          const imageUrl = result.info.secure_url; // Use the direct URL provided by Cloudinary
          setUploadedImage(imageUrl); // Show preview
          setImageUrl(imageUrl); // Pass URL to parent component
          setError("");
        }
      }
    );
  };

  const handleRemoveImage = () => {
    setUploadedImage("");
    setImageUrl("");
  };

  return (
    <div className="w-full">
      {uploadedImage ? (
        <div className="mb-4">
          <img
            src={uploadedImage}
            alt="Uploaded Preview"
            className="mb-2 w-20 h-20 object-contain rounded-lg shadow-md"
          />
          <button
            type="button"
            className="px-4 py-2 bg-red-600 text-white text-sm font-medium rounded-lg hover:bg-red-700 transition duration-300 shadow-md"
            onClick={handleRemoveImage}
          >
            Delete/Change Image
          </button>
        </div>
      ) : (
        <div className="text-center">
          <button
            type="button"
            onClick={handleUpload}
            className="w-full px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
          >
            Upload Image
          </button>
          {loading && <p className="text-sm text-blue-500 mt-2">Uploading...</p>}
          {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default CloudinaryUpload;