import { ClipboardIcon } from "@heroicons/react/24/outline";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirebase } from "../common/contexts/FirebaseContext";
import { Button } from "../ui/Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/Dialog";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import { Switch } from "../ui/Switch";
import { viewPublicCollection } from "../utils/Links";

export default function EditCollectionModal({
  isOpen,
  onClose,
  collection: collectionData,
  onSaveCollection,
  onDeleteCollection,
}) {
  const { firestore } = useFirebase();
  const [isPublic, setIsPublic] = useState(collectionData?.isPublic || false);
  const [name, setName] = useState(collectionData?.name || "");
  const [publicUrl, setPublicUrl] = useState("");
  const [hasItems, setHasItems] = useState(false);
  const [imageUrl, setImageUrl] = useState(collectionData?.imageUrl || "");

  useEffect(() => {
    if (collectionData) {
      setIsPublic(collectionData.isPublic || false);
      setName(collectionData.name || "");
      setImageUrl(collectionData.imageUrl || "");
    }
  }, [collectionData]);

  useEffect(() => {
    if (collectionData) {
      const checkIfCollectionHasItems = async () => {
        try {
          const itemsCollectionRef = collection(
            firestore,
            "collections",
            collectionData.id,
            "items"
          );
          const itemsSnapshot = await getDocs(itemsCollectionRef);
          setHasItems(!itemsSnapshot.empty);
        } catch (error) {
          console.error("Error checking for items in collection:", error);
        }
      };

      checkIfCollectionHasItems();
    }
  }, [collectionData, firestore]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!collectionData) {
      console.error("No collection data available.");
      return;
    }

    const updatedCollection = {
      id: collectionData.id,
      name,
      isPublic,
      imageUrl,
    };

    try {
      const collectionRef = doc(firestore, "collections", collectionData.id);
      await updateDoc(collectionRef, updatedCollection);
      onSaveCollection(updatedCollection);
      onClose();
    } catch (error) {
      console.error("Error updating collection:", error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(publicUrl);
    alert("URL copied to clipboard!");
  };

  const handleDeleteClick = () => {
    if (!hasItems) {
      onDeleteCollection(collectionData.id);
      onClose();
    }
  };

  const truncateUrl = (url) => {
    const maxLength = 25; // Customize as needed
    return url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;
  };

  useEffect(() => {
    if (collectionData) {
      if (isPublic) {
        setPublicUrl(
          `${window.location.origin}${viewPublicCollection(collectionData.id)}`
        );
      } else {
        setPublicUrl("");
      }
    }
  }, [isPublic, collectionData]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className="sm:max-w-[450px] p-8 space-y-8">
        <DialogHeader className="p-0">
          <DialogTitle className="text-lg font-semibold text-left">
            Edit Collection
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Collection Name Field with Character Countdown */}
          <div className="relative">
            <label
              htmlFor="name"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              Collection Name <span className="text-red-500">*</span>
            </label>
            <Input
              id="name"
              name="name"
              required
              aria-required="true"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={21}
              className="w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:ring-2 focus:ring-indigo-600"
            />
            <p className="text-xs text-gray-500 mt-1 text-right">
              {21 - name.length} characters remaining
            </p>
          </div>

          {/* Public Toggle with Truncated URL Display */}
          <div>
            <Label
              htmlFor="isPublic"
              className="text-sm font-medium text-gray-900 mb-2"
            >
              Make Public
            </Label>
            <div className="flex items-center justify-between space-x-3 w-full">
              <Switch
                id="isPublic"
                name="isPublic"
                checked={isPublic}
                onChange={(checked) => {
                  setIsPublic(checked);
                  if (!checked) setPublicUrl("");
                }}
              />
              {isPublic && publicUrl && (
                <div className="flex items-center bg-gray-100 p-2 rounded-md w-full ml-2 space-x-2">
                  <span className="text-sm text-gray-700 truncate flex-1">
                    {truncateUrl(publicUrl)}
                  </span>
                  <Button variant="ghost" size="sm" onClick={handleCopy}>
                    <ClipboardIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              )}
            </div>
          </div>

          {/* Buttons Row */}
          <div className="flex flex-col items-start mt-6">
            <div className="flex items-center justify-between w-full">
              {/* Delete button */}
              <Button
                type="button"
                variant="destructive"
                onClick={handleDeleteClick}
                disabled={hasItems}
                className={`px-4 py-2 ${
                  hasItems ? "cursor-not-allowed opacity-50" : ""
                }`}
              >
                Delete
              </Button>

              {/* Close and Update buttons */}
              <div className="flex space-x-4">
                <Button variant="ghost" onClick={onClose}>
                  Close
                </Button>
                <Button type="submit">Update</Button>
              </div>
            </div>

            {/* Text directly under the delete button */}
            {hasItems && (
              <p className="text-xs text-gray-500 mt-2">
                Only deletable if empty.
              </p>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
