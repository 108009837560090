import _logo from "./img/logo_full.png";
export const SUCCESS_REDIRECT_URL = "/my/collections";

export const STRIPE_PORTAL_ID = ""; // Optional ID of a portal configuration
export const STRIPE_MONTHLY_PRICE_ID = "price_1QJR8sIwkAI8JQRmcZXwaN9q";
export const STRIPE_ANNUAL_PRICE_ID = "";
export const NAV_VISIBLE = true;
export const LOGO = _logo;
export const MAX_COLLECTIONS = {
  subscribed: 20,
  unsubscribed: 2,
};
export const MAX_ITEMS = {
  subscribed: 30,
  unsubscribed: 10,
};

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCbpUPnMIrnR0_t7w875htFGjMyt3TKmu0",
  authDomain: "collectify-26f32.firebaseapp.com",
  projectId: "collectify-26f32",
  storageBucket: "collectify-26f32.firebasestorage.app",
  messagingSenderId: "63821186357",
  appId: "1:63821186357:web:ffd00835c62cb7e423787c",
  measurementId: "G-Y9EG5Q5KCL",
};
