// src/components/Billing/BillingModal.js
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react"; // Import useState
import {
  STRIPE_ANNUAL_PRICE_ID,
  STRIPE_MONTHLY_PRICE_ID,
  STRIPE_PORTAL_ID,
} from "../../constants";
import { useFirebase } from "../contexts/FirebaseContext";
import AppPageLayout from "../layouts/AppPageLayout";
import Panel from "../layouts/Panel";

import SubscriptionCard from "../components/SubscriptionCard";

export default function BillingPage() {
  const { firestore, functions, currentUser, isSubscribed } = useFirebase();
  // Add loading state
  const [loading, setLoading] = useState(false);

  // Function to handle Billing Portal button click
  const handleBillingPortal = async () => {
    setLoading(true); // Set loading to true
    try {
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      const { data } = await functionRef({
        returnUrl: window.location.origin,
        locale: "auto", // Optional, defaults to "auto"
        configuration: STRIPE_PORTAL_ID,
      });
      window.location.assign(data.url);
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false); // Reset loading state on error
    }
  };

  // Function to handle Subscribe Monthly button click
  const handleSubscriptionCardClick = async (priceId) => {
    setLoading(true); // Set loading to true
    await checkoutWithPrice(priceId);
  };

  // Function to create a checkout session and redirect
  const checkoutWithPrice = async (priceId) => {
    try {
      console.log("user id", currentUser.uid);

      const checkoutSessionsRef = collection(
        firestore,
        "customers",
        currentUser.uid,
        "checkout_sessions"
      );

      // Adding a new document to the checkout_sessions collection
      const docRef = await addDoc(checkoutSessionsRef, {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      // Listening for changes on the added document (real-time updates)
      onSnapshot(docRef, (snap) => {
        const data = snap.data();
        if (data) {
          const { error, url } = data;
          if (error) {
            alert(`An error occurred: ${error.message}`);
            setLoading(false); // Reset loading state on error
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        }
      });
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false); // Reset loading state on error
    }
  };

  return (
    <AppPageLayout>
      <Panel header="Billing">
        {/* Subscription Message */}
        <div className="mb-6 text-center">
          {isSubscribed ? (
            <p className="text-green-600 text-lg font-semibold">
              Thank you for your active subscription!
            </p>
          ) : (
            <h3 className="text-xl font-semibold text-blue-600">
              Ready to unlock all of the features?
            </h3>
          )}
        </div>

        {/* Loader when loading */}
        {loading ? (
          <div className="flex flex-col items-center">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Loading...
            </h2>
            <p className="text-base text-gray-600 mb-4">
              Please be patient, this can take a few seconds.
            </p>
            <div className="flex gap-2">
              <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce"></div>
              <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.2s]"></div>
              <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.4s]"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Subscription Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <SubscriptionCard
                title="Monthly Subscription"
                price="4.99"
                subscriptionId={STRIPE_MONTHLY_PRICE_ID}
                onSubscribe={handleSubscriptionCardClick}
                buttonText="Subscribe Monthly"
              />

              <SubscriptionCard
                title="Annual Subscription"
                price="49.99"
                buttonText="Subscribe Annually"
                subscriptionId={STRIPE_ANNUAL_PRICE_ID}
                onSubscribe={handleSubscriptionCardClick}
              />
            </div>

            {/* Billing Portal Section */}
            <div className="mt-8 p-4 border-t border-gray-200">
              <h3 className="text-md font-medium text-gray-900">
                Go to your Billing Portal
              </h3>
              <p className="text-sm text-gray-600 mb-4">
                {isSubscribed
                  ? "Manage your subscription, update payment details, or view invoices in the billing portal."
                  : "To change your card details, manage your subscription, get invoices, or make any changes to your account, please visit your billing portal."}
              </p>
              <button
                className="bg-blue-500 text-white text-sm px-5 py-2.5 rounded-lg"
                onClick={handleBillingPortal}
              >
                Billing Portal
              </button>
            </div>
          </>
        )}
      </Panel>
    </AppPageLayout>
  );
}
