import React from 'react';

export function Switch({ id, name, checked, onChange }) {
  return (
    <div
      role="switch"
      aria-checked={checked}
      tabIndex={0}
      onClick={() => onChange(!checked)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onChange(!checked);
        }
      }}
      className={`relative inline-flex h-6 w-11 mt-3 cursor-pointer rounded-full transition-colors duration-200 ease-in-out ${
        checked ? 'bg-indigo-600' : 'bg-gray-200'
      } focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
    >
      <span className="sr-only">{name}</span>
      <span
        aria-hidden="true"
        className={`pointer-events-none absolute top-0.5 left-0.5 inline-block h-5 w-5 transform bg-white rounded-full shadow ring-0 transition-transform duration-200 ease-in-out ${
          checked ? 'translate-x-5' : 'translate-x-0'
        }`}
      />
    </div>
  );
}