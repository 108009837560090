// src/components/Sidebar.js
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { viewMyCollection } from "../utils/Links";
import AddCollectionModal from "./AddCollectionModal";

export default function Sidebar({ collections }) {
  const navigate = useNavigate();
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  return (
    <div className={`z-30 w-64 bg-white shadow-md p-4`}>
      <h2 className="text-2xl font-bold mb-4 text-indigo-600">Collections</h2>
      <ul className="space-y-2">
        {collections.map((collection) => (
          <li
            key={collection.id}
            className="flex flex-wrap items-start justify-between p-2 bg-gray-100 rounded-md shadow-sm relative"
          >
            <Button
              variant="ghost"
              className="flex-grow justify-start text-left break-words"
              onClick={() => {
                navigate(viewMyCollection(collection.id));
              }}
            >
              <span className="break-words">{collection.name}</span>
              {collection.isPublic && (
                <GlobeAltIcon className="ml-2 h-4 w-4 flex-shrink-0" />
              )}
            </Button>
          </li>
        ))}
      </ul>
      <Button className="w-full mt-4" onClick={() => setAddModalOpen(true)}>
        New Collection
      </Button>

      {isAddModalOpen && (
        <AddCollectionModal
          isOpen={isAddModalOpen}
          collections={collections}
          onFinish={() => setAddModalOpen(false)}
        />
      )}
    </div>
  );
}
