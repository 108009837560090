import React from 'react';
import classNames from 'classnames';

export function Button({ variant = 'default', size = 'md', className, children, ...props }) {
  const baseStyles = 'inline-flex items-center justify-center font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2';
  const variantStyles = {
    default: 'bg-indigo-600 text-white hover:bg-indigo-700',
    ghost: 'bg-transparent text-gray-900 hover:bg-gray-100',
    destructive: 'bg-red-600 text-white hover:bg-red-700',
  };
  const sizeStyles = {
    sm: 'px-2.5 py-1.5 text-xs',
    md: 'px-4 py-2 text-sm',
    lg: 'px-6 py-3 text-base',
  };

  const classes = classNames(baseStyles, variantStyles[variant], sizeStyles[size], className);

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
}