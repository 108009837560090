import React from 'react';
import FeaturesTextCards from '../common/components/FeaturesTextCards.jsx';
import HeroImageSplitScreen from '../common/components/HeroImageSplitScreen.jsx';
import HeroVideoSplitScreen from '../common/components/HeroVideoSplitScreen.jsx';
import TwoCardPricing from '../common/components/TwoCardPricing.jsx'; // New Pricing Section
import SocialProofCarousel from '../common/components/SocialProofCarousel.jsx';
import AppPageLayout from '../common/layouts/AppPageLayout.js';
import Panel from '../common/layouts/Panel.js';


const FEATURES = [
  {
    name: 'User Registration',
    description: 'Easily create an account to start managing collections.<br /><br />With secure access, you can return anytime to update, organize, and share your collections.',
  },
  {
    name: 'Organize Collections',
    description: 'Create named collections, such as “Comic Book Collection” or “Vintage Watches,” to categorize your items.',
  },
  {
    name: 'Collection Cards',
    description: 'Each item is displayed as a visually appealing card, styled with a unique playing card theme.<br /><br />Customize each card with fields like title, description, price, and description.<br /><br />Edit or delete individual cards or entire collections whenever needed.',
  },
  {
    name: 'Track and Update Values',
    description: 'Easily track item values over time.<br /><br />Periodically update prices or other attributes to reflect current market conditions or personal appraisals.',
  },
  {
    name: 'Edit and Delete',
    description: 'Modify any item details as your collection grows or changes.<br /><br />Remove cards or entire collections as needed.',
  },
  {
    name: 'Sharing Made Easy',
    description: 'Instantly generate a unique URL for any collection, allowing you to share it with friends or potential buyers.<br /><br />Shared collections can be viewed without an account, making it simple for others to see your items privately.',
  },
  {
    name: 'Selling Capabilities',
    description: 'Mark items as "For Sale" to show interest in selling.<br /><br />Set a price for each item to make it easy for potential buyers to inquire or make offers directly.',
  }
];

function LandingPage() {
  return (
    <AppPageLayout>
      <Panel>
        <HeroImageSplitScreen />
        <SocialProofCarousel companies={['reddit.com','hackernews.com','producthunt.com','linkedin.com',]}/>
        <FeaturesTextCards 
          features={FEATURES} 
          heading="What is Collectify?" 
          subheading={[
            "Collectify makes managing your personal collections simple and private.",
            <br key="break2" />,
            "With Collectify, you can catalog your items, update details, and even share or sell specific items—all without requiring others to create accounts or log in."
          ]}
        />        
        <HeroVideoSplitScreen />
        <TwoCardPricing />  
      </Panel>
    </AppPageLayout>
  );
}

export default LandingPage;