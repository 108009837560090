// src/common/layouts/AppPageLayout.js
import { NAV_VISIBLE } from "../../constants";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
export default function AppPageLayout({
  hideNav = !NAV_VISIBLE,
  hideFooter = false,
  children,
}) {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {!hideNav && <NavBar />}
      <div className="w-full flex justify-center p-4 flex-1">{children}</div>
      {!hideFooter && <Footer />}
    </div>
  );
}
