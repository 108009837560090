// src/constants/Currencies.js

const currencies = [
  // Top preferred currencies
  { id: 'USD', name: 'USD' },
  { id: 'AUD', name: 'AUD' },
  { id: 'GBP', name: 'GBP' },

  // Southeast Asian currencies
  { id: 'THB', name: 'THB' }, // Thailand
  { id: 'SGD', name: 'SGD' }, // Singapore
  { id: 'INR', name: 'INR' }, // India
  { id: 'IDR', name: 'IDR' }, // Indonesia
  { id: 'MYR', name: 'MYR' }, // Malaysia
  { id: 'PHP', name: 'PHP' }, // Philippines
  { id: 'VND', name: 'VND' }, // Vietnam
  { id: 'KHR', name: 'KHR' }, // Cambodia
  { id: 'MMK', name: 'MMK' }, // Myanmar
  { id: 'LAK', name: 'LAK' }, // Laos
  { id: 'LKR', name: 'LKR' }, // Sri Lanka
  { id: 'BDT', name: 'BDT' }, // Bangladesh
  { id: 'PKR', name: 'PKR' }, // Pakistan
  { id: 'NPR', name: 'NPR' }, // Nepal
  { id: 'MVR', name: 'MVR' }, // Maldives
  { id: 'BND', name: 'BND' }, // Brunei

  // Other world currencies
  { id: 'EUR', name: 'EUR' }, // Euro
  { id: 'CAD', name: 'CAD' }, // Canadian Dollar
  { id: 'JPY', name: 'JPY' }, // Japanese Yen
  { id: 'CNY', name: 'CNY' }, // Chinese Yuan
  { id: 'CHF', name: 'CHF' }, // Swiss Franc
  { id: 'NZD', name: 'NZD' }, // New Zealand Dollar
  { id: 'HKD', name: 'HKD' }, // Hong Kong Dollar
  { id: 'KRW', name: 'KRW' }, // South Korean Won
  { id: 'MXN', name: 'MXN' }, // Mexican Peso
  { id: 'ZAR', name: 'ZAR' }, // South African Rand
  { id: 'BRL', name: 'BRL' }, // Brazilian Real
  { id: 'RUB', name: 'RUB' }, // Russian Ruble
  { id: 'TRY', name: 'TRY' }, // Turkish Lira
  { id: 'SAR', name: 'SAR' }, // Saudi Riyal
  { id: 'AED', name: 'AED' }, // United Arab Emirates Dirham
  { id: 'AFN', name: 'AFN' }, // Afghan Afghani
  { id: 'ALL', name: 'ALL' }, // Albanian Lek
  { id: 'DZD', name: 'DZD' }, // Algerian Dinar
  { id: 'AOA', name: 'AOA' }, // Angolan Kwanza
  { id: 'ARS', name: 'ARS' }, // Argentine Peso
  { id: 'AMD', name: 'AMD' }, // Armenian Dram
  { id: 'AWG', name: 'AWG' }, // Aruban Florin
  { id: 'AZN', name: 'AZN' }, // Azerbaijani Manat
  { id: 'BSD', name: 'BSD' }, // Bahamian Dollar
  { id: 'BHD', name: 'BHD' }, // Bahraini Dinar
  { id: 'BBD', name: 'BBD' }, // Barbadian Dollar
  { id: 'BYN', name: 'BYN' }, // Belarusian Ruble
  { id: 'BZD', name: 'BZD' }, // Belize Dollar
  { id: 'XOF', name: 'XOF' }, // CFA Franc BCEAO
  { id: 'BMD', name: 'BMD' }, // Bermudian Dollar
  { id: 'BTN', name: 'BTN' }, // Bhutanese Ngultrum
  { id: 'BOB', name: 'BOB' }, // Bolivian Boliviano
  { id: 'BAM', name: 'BAM' }, // Bosnia-Herzegovina Convertible Mark
  { id: 'BWP', name: 'BWP' }, // Botswana Pula
  { id: 'NOK', name: 'NOK' }, // Norwegian Krone
  { id: 'BGN', name: 'BGN' }, // Bulgarian Lev
  { id: 'BIF', name: 'BIF' }, // Burundian Franc
  { id: 'CVE', name: 'CVE' }, // Cape Verdean Escudo
  { id: 'KYD', name: 'KYD' }, // Cayman Islands Dollar
  { id: 'XAF', name: 'XAF' }, // CFA Franc BEAC
  { id: 'CLP', name: 'CLP' }, // Chilean Peso
  { id: 'COP', name: 'COP' }, // Colombian Peso
  { id: 'KMF', name: 'KMF' }, // Comorian Franc
  { id: 'CDF', name: 'CDF' }, // Congolese Franc
  { id: 'CRC', name: 'CRC' }, // Costa Rican Colón
  { id: 'HRK', name: 'HRK' }, // Croatian Kuna
  { id: 'CZK', name: 'CZK' }, // Czech Koruna
  { id: 'DKK', name: 'DKK' }, // Danish Krone
  { id: 'DJF', name: 'DJF' }, // Djiboutian Franc
  { id: 'DOP', name: 'DOP' }, // Dominican Peso
  { id: 'EGP', name: 'EGP' }, // Egyptian Pound
  { id: 'ERN', name: 'ERN' }, // Eritrean Nakfa
  { id: 'SZL', name: 'SZL' }, // Eswatini Lilangeni
  { id: 'ETB', name: 'ETB' }, // Ethiopian Birr
  { id: 'FKP', name: 'FKP' }, // Falkland Islands Pound
  { id: 'FJD', name: 'FJD' }, // Fijian Dollar
  { id: 'GMD', name: 'GMD' }, // Gambian Dalasi
  { id: 'GEL', name: 'GEL' }, // Georgian Lari
  { id: 'GHS', name: 'GHS' }, // Ghanaian Cedi
  { id: 'GIP', name: 'GIP' }, // Gibraltar Pound
  { id: 'GTQ', name: 'GTQ' }, // Guatemalan Quetzal
  { id: 'GNF', name: 'GNF' }, // Guinean Franc
  { id: 'GYD', name: 'GYD' }, // Guyanese Dollar
  { id: 'HTG', name: 'HTG' }, // Haitian Gourde
  { id: 'HNL', name: 'HNL' }, // Honduran Lempira
  { id: 'ISK', name: 'ISK' }, // Icelandic Króna
  { id: 'IQD', name: 'IQD' }, // Iraqi Dinar
  { id: 'ILS', name: 'ILS' }, // Israeli New Shekel
  { id: 'JMD', name: 'JMD' }, // Jamaican Dollar
  { id: 'JOD', name: 'JOD' }, // Jordanian Dinar
  { id: 'KZT', name: 'KZT' }, // Kazakhstani Tenge
  { id: 'KES', name: 'KES' }, // Kenyan Shilling
  { id: 'KWD', name: 'KWD' }, // Kuwaiti Dinar
  { id: 'KGS', name: 'KGS' }, // Kyrgystani Som
  { id: 'LRD', name: 'LRD' }, // Liberian Dollar
  { id: 'LYD', name: 'LYD' }, // Libyan Dinar
  { id: 'MOP', name: 'MOP' }, // Macanese Pataca
  { id: 'MGA', name: 'MGA' }, // Malagasy Ariary
  { id: 'MKD', name: 'MKD' }, // Macedonian Denar
  { id: 'MGA', name: 'MGA' }, // Malawian Kwacha
  { id: 'MNT', name: 'MNT' }, // Mongolian Tögrög
  { id: 'MAD', name: 'MAD' }, // Moroccan Dirham
  { id: 'MZN', name: 'MZN' }, // Mozambican Metical
  { id: 'NAD', name: 'NAD' }, // Namibian Dollar
  { id: 'NGN', name: 'NGN' }, // Nigerian Naira
  { id: 'OMR', name: 'OMR' }, // Omani Rial
  { id: 'PGK', name: 'PGK' }, // Papua New Guinean Kina
  { id: 'PYG', name: 'PYG' }, // Paraguayan Guaraní
  { id: 'PEN', name: 'PEN' }, // Peruvian Sol
  { id: 'QAR', name: 'QAR' }, // Qatari Riyal
  { id: 'RON', name: 'RON' }, // Romanian Leu
  { id: 'RWF', name: 'RWF' }, // Rwandan Franc
  { id: 'SHP', name: 'SHP' }, // Saint Helena Pound
  { id: 'WST', name: 'WST' }, // Samoan Tala
  { id: 'STD', name: 'STD' }, // São Tomé and Príncipe Dobra
  { id: 'SCR', name: 'SCR' }, // Seychellois Rupee
  { id: 'SLL', name: 'SLL' }, // Sierra Leonean Leone
  { id: 'SOS', name: 'SOS' }, // Somali Shilling
  { id: 'SSP', name: 'SSP' }, // South Sudanese Pound
  { id: 'SDG', name: 'SDG' }, // Sudanese Pound
  { id: 'SYP', name: 'SYP' }, // Syrian Pound
  { id: 'TJS', name: 'TJS' }, // Tajikistani Somoni
  { id: 'TZS', name: 'TZS' }, // Tanzanian Shilling
  { id: 'TOP', name: 'TOP' }, // Tongan Paʻanga
  { id: 'TTD', name: 'TTD' }, // Trinidad and Tobago Dollar
  { id: 'UGX', name: 'UGX' }, // Ugandan Shilling
  { id: 'UAH', name: 'UAH' }, // Ukrainian Hryvnia
  { id: 'UYU', name: 'UYU' }, // Uruguayan Peso
  { id: 'UZS', name: 'UZS' }, // Uzbekistani Soʻm
  { id: 'VUV', name: 'VUV' }, // Vanuatu Vatu
  { id: 'XPF', name: 'XPF' }, // CFP Franc
  { id: 'YER', name: 'YER' }, // Yemeni Rial
  { id: 'ZMW', name: 'ZMW' }, // Zambian Kwacha
  { id: 'ZWL', name: 'ZWL' }, // Zimbabwean Dollar
];

export default currencies;